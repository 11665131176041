var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Modificar Producto",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "left",
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Nombre", prop: "nombre" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.nombre,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nombre", $$v)
                      },
                      expression: "form.nombre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Descripción", prop: "descripcion" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.descripcion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "descripcion", $$v)
                      },
                      expression: "form.descripcion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Precio", prop: "precio" } },
                [
                  _c("el-input-number", {
                    attrs: { controls: false },
                    model: {
                      value: _vm.form.precio,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "precio", $$v)
                      },
                      expression: "form.precio"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Visible", prop: "habilitado" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "SI",
                      "inactive-text": "NO"
                    },
                    model: {
                      value: _vm.form.habilitado,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "habilitado", $$v)
                      },
                      expression: "form.habilitado"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Imagen", prop: "imagen" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.onUploadChange
                      }
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }