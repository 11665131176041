<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header"></div>
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="120" label="Tipo">
            <template slot-scope="props">
              <span v-if="props.row.detalleReserva != null">Reserva</span>
              <span v-if="props.row.detalleRetiro != null">Retiro</span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="Cliente">
            <template slot-scope="props">
              <div v-if="props.row.detalleReserva != null">
                <span
                  v-if="props.row.detalleReserva.detalleCliente != null"
                  v-text="props.row.detalleReserva.detalleCliente.nombre"
                ></span>
              </div>
              <div v-if="props.row.detalleRetiro != null">
                <span
                  v-if="props.row.detalleRetiro.detalleCliente != null"
                  v-text="props.row.detalleRetiro.detalleCliente.nombre"
                ></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="Técnico">
            <template slot-scope="props">
              <span
                v-if="props.row.detalleReserva != null"
                v-text="
                  props.row.detalleReserva.detalleTurnoTecnico.detalleUser
                    .username
                "
              ></span>
              <span v-if="props.row.detalleRetiro != null">-</span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="comentario">
            <template slot-scope="props">
              <span v-text="props.row.comentario"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="puntuación">
            <template slot-scope="props">
              <span v-if="props.row.puntuacion == 1"> &starf;</span>
              <span v-if="props.row.puntuacion == 2"> &starf; &starf;</span>
              <span v-if="props.row.puntuacion == 3">
                &starf; &starf; &starf;</span
              >
              <span v-if="props.row.puntuacion == 4">
                &starf; &starf; &starf; &starf;</span
              >
              <span v-if="props.row.puntuacion == 5">
                &starf; &starf; &starf; &starf; &starf;</span
              >
            </template>
          </el-table-column>
          <el-table-column label="Visible" width="105" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.visible"
                :active-value="1"
                :inactive-value="0"
                @change="toogleVisible(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "producto",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/testimonio/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      filtroDescripcion: null,
    };
  },
  methods: {
    async toogleVisible(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id, visible: fila.visible };

      let respuestaApi = await this.$maca.api.post(
        "/testimonio/actualizarVisible",
        params
      );

      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
};
</script>
