<template>
  <div>
    <maca-modal
      titulo="Modificar Producto"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        v-loading="cargando"
        label-position="left"
        label-width="130px"
      >
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Descripción" prop="descripcion">
          <el-input v-model="form.descripcion"></el-input>
        </el-form-item>
        <el-form-item label="Precio" prop="precio">
          <el-input-number
            :controls="false"
            v-model="form.precio"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Visible" prop="habilitado">
          <el-switch
            v-model="form.habilitado"
            :active-value="1"
            :inactive-value="0"
            active-text="SI"
            inactive-text="NO"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Imagen" prop="imagen">
          <el-upload
            class="avatar-uploader"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="onUploadChange"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-producto",
  data() {
    return {
      form: {
        nombre: null,
        descripcion: null,
        precio: null,
        habilitado: 1,
        imagen: null,
      },
      imageUrl: null,
      impedirClose: false,
      cargando: false,
      id: 0,
      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        precio: [
          {
            required: true,
            message: "Por favor introduzca el precio.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.form.imagen = null;
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.form.imagen = null;
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get("/producto/obtenerDatos", {
        id: this.id,
      });

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.nombre;
        this.form.descripcion = respuestaApi.datos.descripcion;
        this.form.precio = respuestaApi.datos.precio;
        this.form.habilitado = respuestaApi.datos.habilitado;
        this.imageUrl = respuestaApi.datos.urlImagen;
      } else {
        this.cerrar();
      }
    },
    onUploadChange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.imagen = file.raw;
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let formData = new FormData();

      formData.set("id", this.id);
      formData.set("nombre", this.form.nombre);
      formData.set("precio", this.form.precio);
      formData.set("habilitado", this.form.habilitado);
      if (this.form.descripcion != null && this.form.descripcion != "") {
        formData.set("descripcion", this.form.descripcion);
      }
      if (this.form.imagen != null) {
        formData.append("imagen", this.form.imagen);
      }

      let respuestaApi = await this.$maca.api.post(
        "/producto/actualizar",
        formData
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Producto actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
};
</script>
